import "./Partners.css";
import { useTranslation } from "react-i18next";
import AstanaHub from "../../assets/img/ahub_logo.jpg";

function Partners() {
    const { t } = useTranslation();
    return (
        <div className={'partners__container'}>
            <div className="__title">{t("partners")}</div>
            <div className={'partners__grid'}>
                <img src={AstanaHub} className={'logo'} alt={'Ahub logo'}/>
            </div>
        </div>
    );
}

export default Partners;
