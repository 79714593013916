import "./VideoA.css";


import { ReactComponent as Icon_Alarm1 } from "../assets/icon/Icon_Alarm-1.svg";
import { ReactComponent as Icon_Alarm2 } from "../assets/icon/Icon_Alarm-2.svg";
import { ReactComponent as Icon_Alarm3 } from "../assets/icon/Icon_Alarm-3.svg";
import { ReactComponent as Icon_Alarm4 } from "../assets/icon/Icon_Alarm-4.svg";
import { useTranslation } from "react-i18next";
import Image__forBack from "../assets/img/backGroundForVideo.png";
import Header from "./Header";
import Footer from "./Footer";
import ContactModal from "./main/ContactModal";
import { Link, animateScroll as scroll } from "react-scroll";
import { useEffect } from "react";

function VideoA(props) {
  const { t } = useTranslation();

  const GetDemo = (e) => {
    let contact__modal = document.querySelector(".contact__modal-wrap");
    contact__modal.classList.add("active");
  };
  return (
    <div className="videoA">
      <Header />
      <div className="video__intro" id="intro__inner">
        <img src={Image__forBack} alt="" />
        <div className="container">
          <div className="video__intro-inner">
            <div className="video__intro-title">{t("video__intro-title")}</div>
            <div className="video__intro-subtitle">
              {t("video__intro-subtitle")}
            </div>
            <div className="video__intro-runDemo cursor__pointer" onClick={GetDemo}>
              {t("video__intro-runDemo")}
            </div>
          </div>
        </div>
      </div>
      <div className="video__info" id="solutions">
        <div className="container">
          <div className="video__info-title"> {t("video__info-title")}</div>
        </div>
        <div className="container">
          <div className="video__info-inner">
            <div className="video__info-block">
              <div className="video__info-icon">
                <Icon_Alarm1 />
              </div>
              <div className="video__info-subtitle">
                {t("video__info-subtitle1")}
              </div>
              <div className="video__info-text"> {t("video__info-text1")}</div>
            </div>
            <div className="video__info-block">
              <div className="video__info-icon">
                <Icon_Alarm2 />
              </div>
              <div className="video__info-subtitle">
                {t("video__info-subtitle2")}
              </div>
              <div className="video__info-text">{t("video__info-text2")}</div>
            </div>
            <div className="video__info-block">
              <div className="video__info-icon">
                <Icon_Alarm3 />
              </div>
              <div className="video__info-subtitle">
                {t("video__info-subtitle3")}
              </div>
              <div className="video__info-text">{t("video__info-text3")}</div>
            </div>
            <div className="video__info-block">
              <div className="video__info-icon">
                <Icon_Alarm4 />
              </div>
              <div className="video__info-subtitle">
                {t("video__info-subtitle4")}
              </div>
              <div className="video__info-text">{t("video__info-text4")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="video__demo" id="products">
        <div className="container">
          <div className="demo__inner">
            <div className="demo__text">
              <p> {t("demo__text1")} </p>
              <p> {t("demo__text2")}</p>
            </div>
            <div className="demo__button cursor__pointer" onClick={GetDemo}>{t("video__intro-runDemo")}</div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <ContactModal></ContactModal>
    </div>
  );
}
export default VideoA;
