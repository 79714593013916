import "./Products.css";
import { ReactComponent as Icon_Arrow } from "../../assets/icon/Icon_Arrow.svg";
import { ReactComponent as Icon_Arrow_Fill } from "../../assets/icon/Icon_Arrow_Fill.svg";
import { ReactComponent as Icon_shield } from "../../assets/icon/Icon_shield.svg";
import { ReactComponent as Icon_city } from "../../assets/icon/Icon_city.svg";
import { ReactComponent as Icon_person } from "../../assets/icon/Icon_person.svg";
import Image_surveillance from "../../assets/img/Image_surveillance.png";
import Image_Linker from "../../assets/img/Image_Linker.png";
import Image_SDK from "../../assets/img/Image_SDK.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { Link as LinkRouter, NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";

// what-we-do and Products
function Products(props) {
  const { t } = useTranslation();
  let html = document.querySelector("html");
  const GetDemo = (e) => {
    let contact__modal = document.querySelector(".contact__modal-wrap");
    contact__modal.classList.add("active");
  };
  return (
    <div className="">
      <div className="what-we-do" id="aboutus">
        <div className="container">
          <div className="section">
            <div className="section__text">
              <div className="section__title">{t("section__title")}</div>
              <div className="section__subtitle __subtitle">
                {t("section__subtitle")}
              </div>
            </div>
            <div className="section__list">
              <div className="list__item security">
                <Icon_shield className="list__item-image" />
                {t("list__item_security")}
              </div>
              <div className="list__item city">
                <Icon_city className="list__item-image" />
                Smart City
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="products" id="products">
        <div className="container">
          <div className="__title">{t("products__title")}</div>
          <div className="product__subtitle __subtitle">
            {t("product__subtitle")}
          </div>
        </div>
        <div className="container">
          <div className="products__inner">
            <div className="product__item">
              <div className="product__img">
                <img src={Image_surveillance} alt="" />
              </div>
              <div className="product__content">
                <div className="product__text">
                  <div className="product__title">{t("product__title1")}</div>
                  <div className={t("product__subtitle_class")}>
                    {t("product__subtitle1")}
                  </div>
                </div>
                <NavLink to={"video/" + html.lang} className="product__link">
                  {t("__link")}
                  <Icon_Arrow className="product__icon" />
                  <Icon_Arrow_Fill className="_hover" />
                </NavLink>
              </div>
            </div>
            <div className="product__item">
              <div className="product__img">
                <img src={Image_SDK} alt="" />
              </div>
              <div className="product__content">
                <div className="product__text">
                  <div className="product__title">SDK, API</div>
                  <div className="product__subtitle">
                    {t("product__subtitle3")}
                  </div>
                </div>

                <a className="product__link" onClick={GetDemo}>
                  {t("__link__to_Api")}
                  <Icon_Arrow className="product__icon" />
                  <Icon_Arrow_Fill className="_hover" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
