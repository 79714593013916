import Main from "../components/Main";
import VideoA from "../components/VideoA";
import { useTranslation } from "react-i18next";
import { Translation } from "react-i18next";

// import React, { useEffect, useState } from "react";

import { useCallback, useMemo, useState } from "react";
const TITLES = {
  MAIN: "Main",
  VIDEO: "Video",
};

function Mainpage(props) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(" ");
  const { title } = props;

  const [search, setSearch] = useState("");

  const headerTitle = useMemo(() => {
    switch (title) {
      case TITLES.MAIN:
        return "Main";
      default:
        return "Video";
    }
  }, [title]);
  const className = useMemo(() => {
    switch (title) {
      case TITLES.MAIN:
        return "main";
      default:
        return "video";
    }
  }, [title]);
  const renderContent = useCallback(() => {
    switch (title) {
      case TITLES.MAIN:
        return <Main/>;
      default:
        return <VideoA/>;
    }
  }, [title]);
  return <div className=""> {renderContent()}</div>;
}

export default Mainpage;
