import "./App.css";
import MainPage from "./pages/MainPage";
import ScrollToTop from "./pages/ScrollToTop";
import { useTranslation } from "react-i18next";
import { Translation } from "react-i18next";

// import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Router, Routes, Switch } from "react-router-dom";
import { useState, useEffect, useLocation } from "react";
import i18n from "i18next";


function App() {
  const { t } = useTranslation();
  let html = document.querySelector("html");

  useEffect(()=>{
    const i18nextLng = localStorage.getItem("i18nextLng");
    if (i18nextLng && i18nextLng.length == 2) {
      i18n.changeLanguage(i18nextLng);
    } else {
      i18n.changeLanguage(navigator.language.split('-')[0]);
      localStorage.setItem('i18nextLng', navigator.language.split('-')[0])
    }
  },[])
  return (
    <div className="App">
        <Routes>
          <Route path={"/video/:" + html.lang} element={<MainPage title="Video" />} />
          <Route path="/" element={<MainPage title="Main" />} />
        </Routes>
    </div>
  );
}

export default App;
