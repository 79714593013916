import "./Section.css";
import Image_Deal from "../../assets/img/image_Deal.png";
import Image_Card_1 from "../../assets/img/news_1.jpeg";
import Image_Card_2 from "../../assets/img/news_2.png";
import Icon_Alarm from "../../assets/icon/Icon_Alarm.svg";
import Icon_Avatar from "../../assets/icon/Icon_Avatar.svg";
import Icon_Fraud from "../../assets/icon/Icon_Fraud.svg";
import Icon_Checked from "../../assets/icon/Icon_Checked.svg";
import { useTranslation } from "react-i18next";

//
//
//
function Section() {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="scenario">
        <div className="container">
          <div className="__title">{t("scenario__title")}</div>
          <div className="__subtitle">{t("scenario__subtitle")}</div>
          <div className="scenario__inner">
            <div className="scenario__item">
              <div className="scenario__img">
                <img src={Icon_Alarm} alt="" />
              </div>
              <div className="scenario__content">
                <div className="scenario__title">{t("scenario__title1")}</div>
                <div className="scenario__subtitle">
                  {t("scenario__subtitle1")}
                </div>
              </div>
            </div>
            <div className="scenario__item">
              <div className="scenario__img">
                <img src={Icon_Avatar} alt="" />
              </div>
              <div className="scenario__content">
                <div className="scenario__title">{t("scenario__title2")}</div>
                <div className="scenario__subtitle">
                  {t("scenario__subtitle2")}
                </div>
              </div>
            </div>
            <div className="scenario__item">
              <div className="scenario__img">
                <img src={Icon_Fraud} alt="" />
              </div>
              <div className="scenario__content">
                <div className="scenario__title">{t("scenario__title3")}</div>
                <div className="scenario__subtitle">
                  {t("scenario__subtitle3")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why__us">
        <div className="container">
          <div className="why__inner">
            <div className="why__image">
              <img src={Image_Deal} alt="" />
            </div>
            <div className="why__content">
              <h3 className="why__title niz-25">{t("why__title")}</h3>
              <ul className="why__list">
                <li>
                  <img src={Icon_Checked} alt="" />
                  {t("why__list1")}
                </li>
                <li>
                  <img src={Icon_Checked} alt="" />
                  {t("why__list2")}
                </li>
                <li>
                  <img src={Icon_Checked} alt="" />
                  {t("why__list3")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="news">
        <div className="container">
          <div className="__title">{t("news__title")}</div>
          <div className="news__inner">
            <div className="news__item">
              <div className="news__image">
                <img src={Image_Card_1} alt="" />
              </div>
              <div className="news__content ">
                <div className="news__title">{t("news__title2")}</div>
                <div className="news__info">{t("news__info2")}</div>
                <a
                  target="_blank"
                  href="https://astanahub.com/article/uchastniki-sovmestnoi-programmy-google-for-startups-i-astana-hub-opredeleny"
                  className="news__link"
                >
                  {t("news__link")}
                </a>
              </div>
            </div>
            <div className="news__item mar_l_25">
              <div className="news__image">
                <img src={Image_Card_2} alt="" />
              </div>
              <div className="news__content">
                <div className="news__title">{t("news__title1")}</div>
                <div className="news__info">{t("news__info1")}</div>
                <a
                  target="_blank"
                  href="https://bluescreen.kz/news/11170/bolshie-300-vienchurnykh-inviestorov-i-startapov-vstrietilis-v-almaty"
                  className="news__link"
                >
                  {t("news__link")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
