import "./Main.css";
import Solutions from "./main/Solutions";
import Products from "./main/Products";
import Section from "./main/Section";
import ContactModal from "./main/ContactModal";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Footer from "./Footer";
import Intro from "./Intro";
import Partners from "./main/Partners";

function Main(props) {
  const { t } = useTranslation();
  const form = useRef();
  let setLang = props.setLang;
  let lang = props.lang;
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lc1uumr",
        "template_im4cbon",
        form.current,
        "VbAw30jhHE6ThZZAE"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="main_wrap">
      <ContactModal></ContactModal>
      <Intro setLang={setLang} />
      <Products lang={lang}></Products>
      <Solutions></Solutions>
      <Section></Section>
      <Partners/>
      <div className="contact" id="contact">
        <div className="container">
          <div className="contact__inner">
            <div className="contact__items">
              {" "}
              <div className="contact__text contact__item">
                <p className="contact__title">{t("contact__title")}</p>
                <p className="contact__subtitle __subtitle">
                  {t("contact__subtitle")}
                </p>
              </div>
              <form
                ref={form}
                onSubmit={sendEmail}
                autoComplete="off"
                className="contact__form contact__item"
              >
                <div className="contact__name">
                  {/* <div className="contact__description">
                  {t("contact__description_name")}
                </div> */}
                  <input
                    className="contact__input"
                    type="text"
                    placeholder={t("contact__description_name")}
                    name="contact__name"
                  />
                </div>
                <div className="contact__telephone">
                  {/* <div className="contact__description">
                  {t("contact__description_tel")}
                </div> */}
                  <input
                    className="contact__input"
                    type="tel"
                    placeholder={t("contact__description_tel")}
                    name="contact__tel"
                  />
                </div>
                <div className="contact__name">
                  <input
                    className="contact__input"
                    type="email"
                    placeholder={t("footer__input_email")}
                    name="contact__email"
                  />
                </div>
                <div className="contact__name">
                  <textarea
                    className="contact__input input_text"
                    type=""
                    placeholder={t("footer__input_text")}
                    name="contact__text"
                  />
                </div>
                <input
                  className="contact__btn"
                  type="submit"
                  value={t("btn__send")}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Main;
