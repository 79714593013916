import "./Intro.css";
import { ReactComponent as Icon_Logo } from "../assets/svg/BeyneLogoBlueCircle.svg";
import { ReactComponent as Icon_Logo_text } from "../assets/svg/BeyneLogoWhiteType.svg";
import { ReactComponent as Icon_Globe } from "../assets/icon/Icon_Globe.svg";
import { ReactComponent as Icon_Phone } from "../assets/icon/Icon_Phone.svg";
import { useTranslation } from "react-i18next";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";
import {useEffect} from "react";

const GetDemo = (e) => {
  let contact__modal = document.querySelector(".contact__modal-wrap");
  contact__modal.classList.add("active");
};

function Header(props) {
  function Burger_Menu(e) {
    e.preventDefault();
    let nav = document.querySelector(".header__nav");
    let navToggle = document.querySelector("#navToggle");
    let logo = document.querySelector(".logo");
    let intro__inner = document.querySelector("#intro__inner");
    nav.classList.toggle("show");
    logo.classList.toggle("show");
    intro__inner.classList.toggle("not-show");
    navToggle.classList.toggle("active");
  }

  function CloseMenu(e) {
    let nav = document.querySelector(".header__nav");
    let navToggle = document.querySelector("#navToggle");
    let logo = document.querySelector(".logo");
    let intro__inner = document.querySelector("#intro__inner");
    if (nav.classList.contains("show")) {
      nav.classList.remove("show");
      logo.classList.remove("show");
      intro__inner.classList.remove("not-show");
      navToggle.classList.remove("active");
    }
  }

  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    let some = document.querySelector("html");
    localStorage.setItem('i18nextLng',language)
    some.lang = localStorage.getItem('i18nextLng');
  };

  function Change__ln_toEn(e) {
    e.preventDefault();
    let ru = document.querySelector("#change_toRu");
    let en = document.querySelector("#change_toEn");
    en.classList.add("none");
    ru.classList.remove("none");
  }
  function Change__ln_toRu(e) {
    e.preventDefault();
    let ru = document.querySelector("#change_toRu");
    let en = document.querySelector("#change_toEn");
    ru.classList.add("none");
    en.classList.remove("none");
  }
  function Change_Active(e) {
    e.preventDefault();
    let ru_block = document.querySelector(".language_ru");
    let en_block = document.querySelector(".language_en");
    let ru = document.querySelector("#change_toRu");
    let en = document.querySelector("#change_toEn");

    let nav = document.querySelector(".header__nav");
    let navToggle = document.querySelector("#navToggle");
    let logo = document.querySelector(".logo");
    let intro__inner = document.querySelector(".intro__inner");
    let obj = e.target;
    let obj_text = e.target.textContent;
    if ((obj_text = "EN")) {
      if (obj.classList.contains("active")) {
      } else {
        obj.classList.add("active");
        ru_block.classList.remove("active");
        en.classList.add("none");
        ru.classList.remove("none");

        nav.classList.remove("show");
        logo.classList.remove("show");
        intro__inner.classList.remove("not-show");
        navToggle.classList.remove("active");
      }
    }
    if ((obj_text = "RU")) {
      if (obj.classList.contains("active")) {
      } else {
        obj.classList.add("active");
        en_block.classList.remove("active");
        ru.classList.add("none");
        en.classList.remove("none");

        nav.classList.remove("show");
        logo.classList.remove("show");
        intro__inner.classList.remove("not-show");
        navToggle.classList.remove("active");
      }
    }
  }


  const currentLanguage = i18n.language.split('-')[0].toUpperCase();

  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <LinkRouter to="/" className="intro__logo __logo">
            <Icon_Logo className="logo logo-1" />
            <Icon_Logo_text className="logo logo-2" />
          </LinkRouter>
          <div className="header__nav" id="nav">
            <nav className="nav" id="header-nav">
              <Link
                to="products"
                duration={500}
                className="nav__link"
                onClick={CloseMenu}
              >
                {t("products")}
              </Link>
              <Link
                to="solutions"
                duration={500}
                className="mar_l_25 nav__link"
                onClick={CloseMenu}
              >
                {t("solutions")}
              </Link>
              <Link
                to="aboutus"
                duration={500}
                className="mar_l_25 nav__link video__none"
                onClick={CloseMenu}
              >
                {t("about_us")}
              </Link>
              <Link
                to="contact"
                duration={500}
                className="mar_l_25 nav__link video__none"
                onClick={CloseMenu}
              >
                {t("contact")}
              </Link>
              <div className="mar_l_25 nav__link main__none" onClick={GetDemo}>
                {t("contact")}
              </div>
              <button className="nav-language" onClick={Change_Active}>
                <Icon_Globe className="globe"></Icon_Globe>
                <span
                  onClick={() => changeLanguage("ru")}
                  className="nav-language_choice language_ru active"
                  id="nav-ru"
                >
                  {currentLanguage}
                </span>
                <span
                  onClick={() => changeLanguage("en")}
                  className="nav-language_choice language_en"
                  id="nav-en"
                >
                 {currentLanguage}
                </span>
              </button>
            </nav>
          </div>

          <div
            className="none change_lng"
            onClick={() => changeLanguage("ru")}
            id="change_toRu"
          >
            <div className="language" onClick={Change__ln_toRu}>
              <Icon_Globe className="globe"></Icon_Globe>
              <span className="language_text">{currentLanguage}</span>
            </div>
          </div>
          <div
            className="change_lng"
            onClick={() => changeLanguage("en")}
            id="change_toEn"
          >
            <div className="language" onClick={Change__ln_toEn}>
              <Icon_Globe className="globe"></Icon_Globe>
              <span className="language_text">{currentLanguage}</span>
            </div>
          </div>

          <a href="tel:+77779523235" className="run__demo">
            <Icon_Phone className="intro__phone" />+7 777 952 3235
          </a>
          <button onClick={Burger_Menu} className="burger" id="navToggle">
            <span className="burger_item">Menu</span>
          </button>
        </div>
      </div>
    </header>
  );
}
export default Header;
