import "./Solutions.css";
import Image_Security from "../../assets/img/Image_Security.png";
import Image_City from "../../assets/img/Image_City.png";
import Image_Phone from "../../assets/img/Image_Phone.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

function Solutions() {
  const { t } = useTranslation();
  const GetDemo = (e) => {
    let contact__modal = document.querySelector(".contact__modal-wrap");
    contact__modal.classList.add("active");
  };
  return (
    <div className="solutions" id="solutions">
      <div className="container">
        <div className="solution__inner">
          <div className="solution__item">
            <div className="solution__content">
              <div className="solution__suptitle">
                {t("solution__suptitle")}
              </div>
              <h3 className="solution__title">{t("solution__title1")}</h3>
              <div className="solution__info niz-25">
                <ul className="solution__list">
                  <li className="">{t("solution__list1")}</li>
                  <li className="">{t("solution__list2")}</li>
                  <li className="">{t("solution__list3")}</li>
                </ul>
              </div>
              <div className="solution__demo" onClick={GetDemo}>{t("solution__demo")}</div>
            </div>
            <div className="solution__image">
              <img src={Image_Security} alt="" />
            </div>
          </div>
          <div className="solution__item">
            <div className="solution__image">
              <img src={Image_City} alt="" />
            </div>
            <div className="solution__content mar_l_50">
              <div className="solution__suptitle">
                {t("solution__suptitle")}
              </div>
              <h3 className="solution__title">Smart City</h3>
              <div className="solution__info niz-25">
                {t("solution__info1")}
              </div>
              <div className="solution__demo" onClick={GetDemo}>{t("solution__demo")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
