// import { render } from "@testing-library/react";
// import { useEffect } from "react";
// import { Link } from "react-router-dom";
import "./Intro.css";
import Intro_Video from "../assets/video/intro__video.mp4";
import Intro_Poster from "../assets/video/poster.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import Header from "./Header";

function Intro(props) {
  const { t } = useTranslation();
  let setLang = props.setLang;
  return (
    <div className="intro">
      <Header setLang={setLang}/>
      <div className="container">
        <div className="intro__inner" id="intro__inner">
          <div className="intro__title">{t("intro__title")}</div>
          <div className="intro__subtitle __subtitle">
            {t("intro__subtitle")}
          </div>
          <Link
            to="contact"
            smooth={true}
            duration={550}
            className="intro__consultation"
          >
            {t("intro__consultation")}
          </Link>
        </div>
      </div>
      <video
        src={Intro_Video}
        poster={Intro_Poster}
        className="intro__video"
        autoPlay
        loop
        muted
        preload="auto"
      ></video>
    </div>
  );
}

// function Header() {
//   function Burger_Menu(e) {
//     e.preventDefault();
//     let nav = document.querySelector(".header__nav");
//     let navToggle = document.querySelector("#navToggle");
//     let logo = document.querySelector(".logo");
//     let intro__inner = document.querySelector(".intro__inner");
//     nav.classList.toggle("show");
//     logo.classList.toggle("show");
//     intro__inner.classList.toggle("not-show");
//     navToggle.classList.toggle("active");
//   }

//   const { t, i18n } = useTranslation();

//   const changeLanguage = (language) => {
//     i18n.changeLanguage(language);
//   };

//   function Change__ln_toEn(e) {
//     e.preventDefault();
//     let ru = document.querySelector("#change_toRu");
//     let en = document.querySelector("#change_toEn");
//     en.classList.add("none");
//     ru.classList.remove("none");
//   }
//   function Change__ln_toRu(e) {
//     e.preventDefault();
//     let ru = document.querySelector("#change_toRu");
//     let en = document.querySelector("#change_toEn");
//     ru.classList.add("none");
//     en.classList.remove("none");
//   }
//   function Change_Active(e) {
//     e.preventDefault();
//     let ru_block = document.querySelector(".language_ru");
//     let en_block = document.querySelector(".language_en");
//     let ru = document.querySelector("#change_toRu");
//     let en = document.querySelector("#change_toEn");

//     let nav = document.querySelector(".header__nav");
//     let navToggle = document.querySelector("#navToggle");
//     let logo = document.querySelector(".logo");
//     let intro__inner = document.querySelector(".intro__inner");
//     let obj = e.target;
//     let obj_text = e.target.textContent;
//     console.log(obj_text);
//     if ((obj_text = "EN")) {
//       if (obj.classList.contains("active")) {
//       } else {
//         obj.classList.add("active");
//         ru_block.classList.remove("active");
//         en.classList.add("none");
//         ru.classList.remove("none");

//         nav.classList.remove("show");
//         logo.classList.remove("show");
//         intro__inner.classList.remove("not-show");
//         navToggle.classList.remove("active");
//       }
//     }
//     if ((obj_text = "RU")) {
//       if (obj.classList.contains("active")) {
//       } else {
//         obj.classList.add("active");
//         en_block.classList.remove("active");
//         ru.classList.add("none");
//         en.classList.remove("none");

//         nav.classList.remove("show");
//         logo.classList.remove("show");
//         intro__inner.classList.remove("not-show");
//         navToggle.classList.remove("active");
//       }
//     }
//   }

//   return (
//     <header className="header">
//       <div className="container">
//         <div className="header__inner">
//           <div className="intro__logo __logo">
//             <Icon_Logo className="logo logo-1" />
//             <Icon_Logo_text className="logo logo-2" />
//           </div>
//           <div className="header__nav" id="nav">
//             <nav className="nav" id="header-nav">
//               <a href="#products" className="nav__link">
//                 {t("products")}
//               </a>
//               <a href="#solutions" className="mar_l_25 nav__link">
//                 {t("solutions")}
//               </a>
//               <a href="#aboutus" className="mar_l_25 nav__link">
//                 {t("about_us")}
//               </a>
//               <a href="#contact" className="mar_l_25 nav__link">
//                 {t("contact")}
//               </a>
//               <button className="nav-language" onClick={Change_Active}>
//                 <Icon_Globe className="globe"></Icon_Globe>
//                 <span
//                   onClick={() => changeLanguage("ru")}
//                   className="nav-language_choice language_ru active"
//                   id="nav-ru"
//                 >
//                   RU
//                 </span>
//                 <span
//                   onClick={() => changeLanguage("en")}
//                   className="nav-language_choice language_en"
//                   id="nav-en"
//                 >
//                   EN
//                 </span>
//               </button>
//             </nav>
//           </div>

//           <div
//             className="none change_lng"
//             onClick={() => changeLanguage("ru")}
//             id="change_toRu"
//           >
//             <div className="language" onClick={Change__ln_toRu}>
//               <Icon_Globe className="globe"></Icon_Globe>
//               <span className="language_text">EN</span>
//             </div>
//           </div>
//           <div
//             className="change_lng"
//             onClick={() => changeLanguage("en")}
//             id="change_toEn"
//           >
//             <div className="language" onClick={Change__ln_toEn}>
//               <Icon_Globe className="globe"></Icon_Globe>
//               <span className="language_text">RU</span>
//             </div>
//           </div>

//           <a href="tel:+77087173362" className="run__demo">
//             <Icon_Phone className="intro__phone" />+ 7 708 717 33 62
//           </a>
//           <button onClick={Burger_Menu} className="burger" id="navToggle">
//             <span className="burger_item">Menu</span>
//           </button>
//         </div>
//       </div>
//     </header>
//   );
// }
export default Intro;
