//ContactModal
import "./ContactModal.css";

import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

function ContactModal() {
  const { t } = useTranslation();
  const form = useRef();

  const CloseModal = (e) => {
    let contact__modal = document.querySelector(".contact__modal-wrap");
    contact__modal.classList.remove("active");
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lc1uumr",
        "template_im4cbon",
        form.current,
        "VbAw30jhHE6ThZZAE"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="contact__modal-wrap">
      <div className="contact__modal">
        <div className="contact__modal-title">{t("contact__modal-title")}</div>
        <form
            ref={form}
            onSubmit={sendEmail}
          autoComplete="off"
          className="contact__form contact__item"
        >
          <div className="contact__name">
            <input
              className="contact__input"
              type="text"
              placeholder={t("contact__description_name")}
              name="contact__name"
            />
          </div>
          <div className="contact__telephone">
            <input
              className="contact__input"
              type="tel"
              placeholder={t("contact__description_tel")}
              name="contact__tel"
            />
          </div>
          <div className="contact__name">
            <input
              className="contact__input"
              type="email"
              placeholder={t("footer__input_email")}
              name="contact__email"
            />
          </div>
          <div className="contact__name">
            <textarea
              className="contact__input input_text"
              type=""
              placeholder={t("footer__input_text")}
              name="contact__text"
            />
          </div>
          <input
            className="contact__btn"
            type="submit"
            value={t("btn__send")}
          />
        </form>
        <div className="contact__modal-close" onClick={CloseModal}>
          <div className="close_vector"></div>
        </div>
      </div>
    </div>
  );
}
export default ContactModal;
